import React from "react";
import Nav from "../common/Nav";
import Abouthero from "../Abouthero";

const About = () => {
  return (
    <>
      <Nav />
      <Abouthero />
    </>
  );
};

export default About;
