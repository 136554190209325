import React from "react";
import Nav from "../common/Nav";

const Partner = () => {
  return (
    <>
      <Nav />
    </>
  );
};

export default Partner;
