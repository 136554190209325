import React from "react";
import Nav from "../common/Nav";

const Contact = () => {
  return (
    <>
   <Nav/>
    </>
  );
};

export default Contact;
