import React from "react";
import Nav from "../common/Nav";

const Career = () => {
  return (
    <div>
      <Nav/>
    </div>
  );
};

export default Career;
