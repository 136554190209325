import './App.css';
import MainRoute from './components/common/MainRoute';
import Loader from './components/common/Loader';

function App() {
  return (
    <>
      <MainRoute />
      <Loader /></>
  );
}

export default App;
